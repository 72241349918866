import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./BenefitsOfWorking.module.scss"

const BenefitsOfWorking = ({ strapiData }) => {
  const [hovered, setHovered] = useState('')
  
   
  return (
    <div className={styles.dedicatedTeam}>
      <Container>
        <div>
          <h2
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          ></h2>
          <p className={styles.desc}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          >

          </p>
          <Row className={`${styles.rowCards} gap-20`}>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={4} key={i}>
                <div className={`${styles.cards}`} onMouseEnter={() => setHovered(i)} onMouseLeave={() => setHovered('')}>
                  <div>
                    <img
                      src={ hovered === i ?  v?.image2?.[0]?.localFile?.publicURL : v?.image1?.[0]?.localFile?.publicURL}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                      alt='icon'
                    />
                    <h3 dangerouslySetInnerHTML={{
                      __html: v?.title,
                    }} />
                    <p>{v?.subTitle}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default BenefitsOfWorking;
